import { Dictionary } from "lodash";

export const formatFileInput = (value: any) =>
  value && typeof value === "string" ? { url: value } : value;

interface File {
  rawFile: Blob;
}

export const convertFileToBase64 = (file: File) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
  });

export const existFile = (data: Dictionary<any>) => {
  for (const value of Object.values(data)) {
    if (!!value?.rawFile) {
      return true;
    }
  }
  return false;
};

export const handleFiles = async (data: Dictionary<any>) => {
  data.files = [];
  for (const [key, value] of Object.entries(data)) {
    if (value)
      if (typeof value === "object" && value.rawFile) {
        data.files.push({
          src: await convertFileToBase64(value),
          title: key,
        });
      }
  }
  return data;
};
