import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import { FileField, FileInput } from "react-admin";
import { formatFileInput } from "../../utils/fileUploadUtils";

interface IProps {
  source: string;
  label: string;
}

const CustomFileInput: FC<IProps> = ({ label, source }) => {
  const classes = useStyles();
  return (
    <FileInput
      classes={classes}
      source={source}
      label={label}
      accept="image/*"
      format={formatFileInput}
    >
      <FileField source="url" title="url" />
    </FileInput>
  );
};

export default CustomFileInput;

const useStyles = makeStyles({
  root: {
    "& > div": { display: "flex", alignItems: "center", marginTop: "10px" },
  },
  dropZone: {
    width: "300px",
    height: "50px",
    border: "dashed 2px #a9a9a9",
    padding: "0px",
  },
});
