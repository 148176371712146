import BrushIcon from "@material-ui/icons/Brush";
import WithdrawalIcon from "@material-ui/icons/CallMade";
import UserIcon from "@material-ui/icons/Group";
import MonetizationOnIcon from "@material-ui/icons/MonetizationOn";
import PublicIcon from "@material-ui/icons/Public";
import PaymentIcon from "@material-ui/icons/VerticalAlignBottom";
import EmailIcon from "@material-ui/icons/Email";

import React from "react";
import {
  Admin,
  AuthProvider,
  DataProvider,
  Identifier,
  Resource,
  UserIdentity,
} from "react-admin";
import { client } from "./client";
import { AssetList } from "./components/AssetList";
import { CountryList } from "./components/CountryList";
import CustomLayout from "./components/CustomLayout";
import { DepositList } from "./components/DepositList";
import ThemeDetailEdit from "./components/theme/ThemeDetailEdit";
import ThemeDetailList from "./components/theme/ThemeDetailList";
import { TickerList } from "./components/TickerList";
import { TradeList } from "./components/Trade";
import { UserEdit } from "./components/user/UserEdit";
import { UserList } from "./components/UserList";
import { WithdrawalList } from "./components/WithdrawalList";
import { existFile, handleFiles } from "./utils/fileUploadUtils";
import { CreditList } from "./components/CreditList";
import MailEdit from "./components/mails/MailEdit";
import MailList from "./components/mails/MailList";
import MailCreate from "./components/mails/MailCreate";

// https://marmelab.com/react-admin/DataProviders.html#writing-your-own-data-provider
const dataProvider: DataProvider = {
  getList: async (resource, props) => {
    const {
      pagination: { page, perPage },
      filter,
      sort,
    } = props;

    const offset = (page - 1) * perPage;
    const limit = perPage;
    const query = new URLSearchParams({
      offset: `${offset}`,
      limit: `${limit}`,
      ...filter,
      sort: JSON.stringify(sort),
    });

    const { data, headers } = await client.get(`${resource}?${query}`);

    const total = headers["content-range"].split("/")[1];
    return { data, total: parseInt(total) };
  },
  getOne: (resource, { id }) => client.get(`${resource}/${id}`),

  getMany: async (resource, { ids }) => {
    const { data } = await client.get(
      `${resource}/getMany?ids=[${ids.toString()}]`
    );
    return { data };
  },
  getManyReference: async (resource, props) => {
    const {
      pagination: { page, perPage },
      filter,
      target,
      id,
    } = props;

    const offset = (page - 1) * perPage;
    const limit = perPage;
    const query = new URLSearchParams({
      id,
      target,
      offset: `${offset}`,
      limit: `${limit}`,
      ...filter,
    });

    const { data, headers } = await client.get(
      `${resource}/getManyReference?${query}`
    );

    const total = headers["content-range"].split("/")[1];

    return { data, total: parseInt(total) };
  },
  create: () => {
    throw new Error("NOT_IMPLEMENTED");
  },
  update: async (resource, item) => {
    console.log(item.data);
    if (existFile(item.data)) {
      return client.patch(
        `${resource}/${item.id}`,
        await handleFiles(item.data)
      );
    }

    return client.patch(`${resource}/${item.id}`, item.data);
  },
  updateMany: () => {
    throw new Error("NOT_IMPLEMENTED update many");
  },
  delete: () => {
    throw new Error("NOT_IMPLEMENTED");
  },
  deleteMany: () => {
    throw new Error("NOT_IMPLEMENTED");
  },
};

export interface Identity extends UserIdentity {
  logoPath?: string;
}

// https://marmelab.com/react-admin/Authentication.html#building-your-own-auth-provider
const authProvider: AuthProvider = {
  login: async ({ username: email, password }) => {
    try {
      await client.post("/login", { email, password });
      return;
    } catch (error) {
      return "INVALID CREDENTIALS";
    }
  },
  checkError: async (error) => {
    console.log(error);
    if ([401, 403].includes(error.status)) throw new Error("Unauthorized");
  },
  checkAuth: async () => {
    await client.get("/me");
  },
  logout: async () => {
    try {
      await client.post("/logout");
      return;
    } catch (error) {
      return "INVALID CREDENTIALS";
    }
  },
  getIdentity: async (): Promise<Identity> => {
    const {
      data: { name, ...rest },
    } = await client.get<{ id: Identifier; name: string; logoPath?: string }>(
      "/me"
    );
    return { fullName: name, ...rest };
  },
  getPermissions: async (params) => {},
};

const App = () => (
  <Admin
    layout={CustomLayout}
    dataProvider={dataProvider}
    authProvider={authProvider}
  >
    <Resource name="users" list={UserList} edit={UserEdit} icon={UserIcon} />
    <Resource name="deposits" list={DepositList} icon={PaymentIcon} />
    <Resource name="withdrawals" list={WithdrawalList} icon={WithdrawalIcon} />
    <Resource name="countries" list={CountryList} icon={PublicIcon} />
    <Resource name="assets" list={AssetList} icon={MonetizationOnIcon} />
    <Resource options={{ label: "Markets" }} name="tickers" list={TickerList} />
    <Resource name="accounts" />
    <Resource name="transactions" />
    <Resource name="cryptoAddress" />
    <Resource name="orders" />
    <Resource name="trades" list={TradeList} />
    <Resource name="credits" list={CreditList} />
    <Resource
      name="themes"
      options={{ label: "Theme details" }}
      list={ThemeDetailList}
      edit={ThemeDetailEdit}
      icon={BrushIcon}
    />
    <Resource
      name="mails"
      options={{ label: "Mail options" }}
      list={MailList}
      edit={MailEdit}
      icon={EmailIcon}
    ></Resource>
  </Admin>
);

export default App;
