import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  wrap: { textAlign: "right" },
  date: { whiteSpace: "nowrap" },
  time: { fontSize: "0.75rem", color: "#a9a9a9" },
});

export const ListDateField = ({ record = { createdAt: "" } }) => {
  const classes = useStyles();
  let date = record.createdAt.split(" ");

  if (date.length < 2) {
    date = record.createdAt.split("T");
    date = date.map((d) => d.split(".")[0]);
  }

  return (
    <div className={classes.wrap}>
      <span className={classes.date}>{date[0]}</span>
      <br />
      <span className={classes.time}>{date[1]}</span>
    </div>
  );
};
ListDateField.defaultProps = { label: "Initiated" };
