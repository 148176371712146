import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import {
  ArrayInput,
  Edit,
  EditProps,
  FormTab,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";
import { ColorInput } from "react-admin-color-input";
import CustomFileInput from "./ThemeFileInput";

const useStyles = makeStyles({
  root: {
    "& > div": {
      marginTop: "18px",
    },
  },
});

const ThemeDetailEdit: FC<EditProps> = (props) => {
  const classes = useStyles();
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="Basics">
          <TextInput classes={classes} source="name" />
          <TextInput classes={classes} source="legalName" />
          <TextInput classes={classes} source="mainPageUrl" />
          <TextInput classes={classes} source="helpUrl" />
          <ArrayInput source="footer">
            <SimpleFormIterator>
              <TextInput
                classes={classes}
                source="footerCategory"
                label="Category"
              />
              <ArrayInput source="footerLinks" label="Footer Links">
                <SimpleFormIterator>
                  <TextInput classes={classes} source="title" label="Title" />
                  <TextInput classes={classes} source="url" label="Url" />
                </SimpleFormIterator>
              </ArrayInput>
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="headerLinks">
            <SimpleFormIterator>
              <TextInput classes={classes} source="title" label="Title" />
              <TextInput classes={classes} source="url" label="Url" />
            </SimpleFormIterator>
          </ArrayInput>
          <ArrayInput source="socialLinks">
            <SimpleFormIterator>
              <SelectInput
                source="type"
                label=""
                choices={[
                  { id: "twitter", name: "Twitter" },
                  { id: "telegram", name: "Telegram" },
                  { id: "medium", name: "Medium" },
                  { id: "facebook", name: "Facebook" },
                  { id: "linkedin", name: "Linkedin" },
                  { id: "youtube", name: "Youtube" },
                  { id: "reddit", name: "Reddit" },
                  { id: "discord", name: "Discord" },
                ]}
              />
              <TextInput classes={classes} source="url" label="Url" />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
        <FormTab label="Images">
          <CustomFileInput source="faviconUrl" label="Favicon" />
          <CustomFileInput source="logoLink" label="Logo" />
          <CustomFileInput source="logoBigUrl" label="Logo big" />
          <CustomFileInput source="logoSmallUrl" label="Logo small" />
        </FormTab>
        <FormTab label="Colors">
          <TextField source="id" />
          <ColorInput source="text" />
          <ColorInput source="black" />
          <ColorInput source="white" />
          <ColorInput source="trans" />
          <ColorInput source="textH2" />
          <ColorInput source="warnBg" />
          <ColorInput source="warnFg" />
          <ColorInput source="staging" />
          <ColorInput source="dangerBg" />
          <ColorInput source="dangerFg" />
          <ColorInput source="primaryBg" />
          <ColorInput source="primaryFg" />
          <ColorInput source="successBg" />
          <ColorInput source="successFg" />
          <ColorInput source="textLight" />
          <ColorInput source="disabledBg" />
          <ColorInput source="buttonExtra" />
          <ColorInput source="secondaryBg" />
          <ColorInput source="secondaryFg" />
          <ColorInput source="primaryBgDark" />
          <ColorInput source="primaryBorder" />
          <ColorInput source="textSecondary" />
          <ColorInput source="primaryBgLight" />
          <ColorInput source="secondaryBgDark" />
          <ColorInput source="buttonExtraHover" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};

export default ThemeDetailEdit;
