import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ListProps,
  FilterProps,
  Filter,
  TextInput,
  SelectInput,
} from "react-admin";

import { ListDateField } from "./ListDateField";

const LinkToUserField = ({
  record = { userUniqueReference: "", userEmail: "" },
}) => {
  const link = `/users#/users/${record.userUniqueReference}`;
  return <a href={link}>{record.userEmail}</a>;
};
LinkToUserField.defaultProps = { label: "User" };

export function CreditList(props: Omit<ListProps, "children">) {
  return (
    <List bulkActionButtons={false} {...props} title="Credits">
      <Datagrid rowClick="show">
        <TextField
          source="uuid"
          label="id"
          display={"initial"}
          sortable={false}
        />
        <LinkToUserField />
        <TextField source="status" sortable={false} />
        <TextField source="asset" sortable={false} />
        <TextField source="amount" sortable={false} />
        <TextField source="repaid" sortable={false} />
        <ListDateField label="Timestamp" />
      </Datagrid>
    </List>
  );
}
