import { makeStyles } from "@material-ui/core";
import React from "react";
import { useGetIdentity } from "react-admin";

const useStyles = makeStyles({
  logo: {
    maxWidth: "115px",
  },
});

export const Logo = () => {
  const classes = useStyles();
  const { identity, loading } = useGetIdentity();
  if (loading || !identity || !identity.logoPath) return <></>;
  return (
    <img
      className={classes.logo}
      alt="Logo"
      src={identity.logoPath}
    />
  );
};
