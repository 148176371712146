import React from "react";
import {
  Datagrid,
  List,
  TextField,
  ListProps,
  FilterProps,
  Filter,
  TextInput,
  SelectInput,
} from "react-admin";

import { ListDateField } from "./ListDateField";

import { FormattedDateInput } from "./FormattedDateInput";
function TradeFilter(props: Omit<FilterProps, "children">) {
  return (
    <Filter {...props}>
      <TextInput source="user_email" label="Email" alwaysOn />
      <TextInput source="user_name" label="Full name" alwaysOn />
      <TextInput source="ticker" label="Market name" alwaysOn />
      <SelectInput
        label="Order type"
        source="direction"
        choices={[
          {
            id: "B",
            name: "Buy",
          },
          {
            id: "S",
            name: "Sell",
          },
        ]}
        alwaysOn
        translateChoice={false}
      />

      <FormattedDateInput
        label="Order date from"
        source="created_from"
        alwaysOn
      />
      <FormattedDateInput label="Order date to" source="created_to" alwaysOn />
    </Filter>
  );
}
export function TradeList(props: Omit<ListProps, "children">) {
  return (
    <List
      bulkActionButtons={false}
      filters={<TradeFilter />}
      {...props}
      title="Trades"
    >
      <Datagrid rowClick="show">
        <TextField
          source="id"
          label="Order ID"
          display={"initial"}
          sortable={false}
        />
        <TextField source="user" label="Full name" sortable={false} />
        <TextField source="email" label="Email" sortable={false} />
        <TextField source="market" label="Market name" sortable={false} />
        <TextField source="market_type" label="Market type" sortable={false} />
        <TextField source="direction" label="Order type" sortable={false} />
        <TextField source="quantity" sortable={false} />
        <TextField source="price" sortable={false} />
        <TextField source="total_amount" sortable={false} />
        <TextField source="fee" label="Order fee" sortable={false} />
        <ListDateField label="Order date/ time" />
      </Datagrid>
    </List>
  );
}
