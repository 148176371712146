import React from "react";
import {
  Datagrid,
  List,
  NumberField,
  TextField,
  TextInput,
  Filter,
  SelectInput,
  CheckboxGroupInput,
  AutocompleteInput,
  FilterProps,
  ListProps,
} from "react-admin";
import { getUserStatuses, getCountriesForUserListPage } from "../api/User";
import { useChoices } from "../hooks/useChoices";
import { toChoice } from "../types/Choice";
import { DefaultPagintion } from "./DefaultPagination";
import { FormattedDateInput } from "./FormattedDateInput";

function UserFilter(props: Omit<FilterProps, "children">) {
  const statusChoices = useChoices(getUserStatuses);
  const countryChoices = useChoices(getCountriesForUserListPage);

  return (
    <Filter {...props}>
      <TextInput source="uniqueReference" label="Reference" alwaysOn />
      <TextInput source="email" alwaysOn />
      <TextInput source="name" label="Full name" alwaysOn />
      <SelectInput
        source="status"
        label="User status"
        choices={statusChoices}
        alwaysOn
        translateChoice={false}
      />
      <AutocompleteInput
        label="Country of residency"
        source="country"
        choices={countryChoices}
        alwaysOn
        translateChoice={false}
        resettable
      />
      <CheckboxGroupInput
        source="tier"
        choices={[0, 1, 2, 3, 4].map(toChoice)}
        translateChoice={false}
        alwaysOn
      />
      <CheckboxGroupInput
        source="risk"
        label="Risk level"
        choices={[
          { id: "LOW", name: "L" },
          { id: "MEDIUM", name: "M" },
          { id: "HIGH", name: "H" },
        ]}
        translateChoice={false}
        alwaysOn
      />
      <FormattedDateInput
        label="Registration date from"
        source="created_from"
        alwaysOn
      />
      <FormattedDateInput
        label="Registration date to"
        source="created_till"
        alwaysOn
      />
      <FormattedDateInput
        label="KYC date from"
        source="kyc_date_from"
        alwaysOn
      />
      <FormattedDateInput label="KYC date to" source="kyc_date_till" alwaysOn />
    </Filter>
  );
}

export function UserList(props: Omit<ListProps, "children">) {
  return (
    <List
      bulkActionButtons={false}
      filters={<UserFilter />}
      {...props}
      title="Users"
      pagination={<DefaultPagintion />}
      perPage={25}
    >
      <Datagrid rowClick="edit">
        <TextField source="uniqueReference" label="Reference" />
        <TextField source="email" />
        <TextField source="name" label="Full name" />
        <NumberField label="Tier" source="tier.level" />
        <TextField label="Country of residency" source="tier.country" />
        <TextField source="status" label="User status" />
        <TextField source="risk_level" label="Risk level" />
        <NumberField source="risk_score" />
        <TextField source="registered" label="Registration date/ time" />
        <TextField source="kyc_date" label="KYC date/ time" />
      </Datagrid>
    </List>
  );
}
