import { useEffect, useState } from "react";
import { Choice, toChoice } from "../types/Choice";

export function useChoices(apiMethod: () => Promise<string[] | Choice[]>) {
  const [choices, setChoices] = useState<Choice[]>([]);
  useEffect(() => {
    apiMethod().then(response => {
      if (response.length < 0) return;
      if (typeof response[0] === "string") return setChoices((response as string[]).map(toChoice));
      setChoices(response as Choice[]);
    })
  }, [apiMethod]);
  return choices;
}