import * as React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  wrap: { textAlign: "right" },
  fee: { fontSize: "0.75rem", color: "#a9a9a9" },
});

export const AmountFeeField = ({ record = { amount: "", fee: "" } }) => {
  const classes = useStyles();
  return (
    <div className={classes.wrap}>
      <span>{record.amount}</span>
      <br />
      <span className={classes.fee}>{record.fee}</span>
    </div>
  );
};
AmountFeeField.defaultProps = { label: "Amount/Costs" };
