import React from "react";
import {
  Datagrid,
  Filter,
  FilterProps,
  List,
  ListProps,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { usePaymentChoices } from "../hooks/usePaymentChoices";
import { AmountFeeField } from "./AmountFeeField";
import { DefaultPagintion } from "./DefaultPagination";
import { FormattedDateInput } from "./FormattedDateInput";
import { LinkToUserField } from "./LinkToUserField";
import { ListDateField } from "./ListDateField";

function WithdrawalFilter(props: Omit<FilterProps, "children">) {
  const { withdrawalMethodChoices, withdrawalStatusChoices, assetChoices } =
    usePaymentChoices();

  return (
    <Filter {...props}>
      <TextInput source="email" alwaysOn />
      <SelectInput
        label="Asset name"
        source="asset"
        choices={assetChoices}
        alwaysOn
        translateChoice={false}
      />
      <SelectInput
        source="method"
        choices={withdrawalMethodChoices}
        alwaysOn
        translateChoice={false}
      />
      <SelectInput
        source="status"
        label="Payment status"
        choices={withdrawalStatusChoices}
        alwaysOn
        translateChoice={false}
      />
      <TextInput source="address" label="Address/ Account number" alwaysOn />
      <TextInput source="external_id" label="External ID" alwaysOn />
      <FormattedDateInput
        label="Payment date from"
        source="initiated_from"
        alwaysOn
      />
      <FormattedDateInput
        label="Payment date to"
        source="initiated_to"
        alwaysOn
      />
    </Filter>
  );
}

export function WithdrawalList(props: Omit<ListProps, "children">) {
  return (
    <List
      bulkActionButtons={false}
      filters={<WithdrawalFilter />}
      pagination={<DefaultPagintion />}
      {...props}
      perPage={25}
    >
      <Datagrid rowClick="show">
        <TextField label="Payment ID" source="uuid" />
        <LinkToUserField label="Email" />
        <TextField source="method" />
        <TextField label="PSP" source="psp" />
        <TextField source="status" label="Payment status" />
        <TextField source="asset" label="Asset name" />
        <AmountFeeField label="Payment amount/ costs" />
        <TextField label="Address/ Account number" source="address" />
        <TextField label="External ID" source="external_id" />
        <ListDateField label="Payment date/ time" />
      </Datagrid>
    </List>
  );
}
