import { useEffect, useState } from "react";
import { GeneralData, getDataForPaymentFilters } from "../api/GeneralData";
import { Choice, toChoice } from "../types/Choice";

export function usePaymentChoices() {
  const [choices, setChoices] = useState<Record<
    "depositStatusChoices" |
    "withdrawalStatusChoices" |
    "depositMethodChoices" |
    "withdrawalMethodChoices" |
    "assetChoices", Choice[]>>({
      depositStatusChoices: [],
      withdrawalStatusChoices: [],
      depositMethodChoices: [],
      withdrawalMethodChoices: [],
      assetChoices: []
    });
  useEffect(() => {
    getDataForPaymentFilters().then(({ deposit_statuses, deposit_methods, withdrawal_statuses, withdrawal_methods, assets }: GeneralData) => {
      setChoices({
        depositStatusChoices: deposit_statuses.map(toChoice),
        withdrawalStatusChoices: withdrawal_statuses.map(toChoice),
        depositMethodChoices: deposit_methods.map(toChoice),
        withdrawalMethodChoices: withdrawal_methods.map(toChoice),
        assetChoices: assets.map(toChoice)
      });
    });
  }, []);
  return choices;
}