import React, { FC } from "react";
import { Datagrid, List, ListProps, TextField } from "react-admin";

const ThemeDetailList: FC<ListProps> = (props) => (
  <List bulkActionButtons={false} {...props} pagination={false}>
    <Datagrid rowClick="edit">
      <TextField source="id" sortable={false} />
      <TextField source="name" display={"initial"} sortable={false} />
    </Datagrid>
  </List>
);

export default ThemeDetailList;
