import { makeStyles } from "@material-ui/core";
import React, { FC } from "react";
import {
  ArrayInput,
  Edit,
  EditProps,
  FormTab,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TabbedForm,
  TextField,
  TextInput,
  UrlField,
  required,
} from "react-admin";

const useStyles = makeStyles({
  root: {
    "& > div": {
      marginTop: "18px",
    },
  },
});

export const MailEdit: FC<EditProps> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput disabled label="Id" source="id" />
      <SelectInput
        source="transport.type"
        label=""
        choices={[
          { id: "gmail", name: "Gmail" },
          { id: "smtp", name: "SMTP" },
          { id: "secure_smtp", name: "SECURE_SMTP" },
          { id: "sendgrid", name: "SENDGRID" },
        ]}
      />
      <TextInput source="name" validate={required()} />
      <TextInput source="layoutKey" validate={required()} />
      <TextInput source="address" validate={required()} />
      <TextInput source="transport.host" />
      <TextInput source="transport.username" />
      <TextInput
        source="transport.password"
        type="password"
        validate={required()}
      />
    </SimpleForm>
  </Edit>
);
export default MailEdit;
