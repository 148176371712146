import React from "react";
import { Datagrid, List, TextField, ListProps } from "react-admin";

export function AssetList(props: Omit<ListProps, "children">) {
  return (
    <List bulkActionButtons={false} {...props} pagination={false}>
      <Datagrid rowClick="show">
        <TextField source="name" sortable={false} />
        <TextField source="full_name" sortable={false} />
        <TextField label="Shown" source="status" sortable={false} />
        <TextField source="depositable" sortable={false} />
        <TextField source="withdrawable" sortable={false} />
        <TextField source="decimal_places" sortable={false} />
        <TextField source="description" sortable={false} />
        <TextField source="type" sortable={false} />
      </Datagrid>
    </List>
  );
}
