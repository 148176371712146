import React from "react";
import { Datagrid, List, ListProps, TextField } from "react-admin";

export function CountryList(props: Omit<ListProps, "children">) {
  return (
    <List bulkActionButtons={false} {...props} pagination={false}>
      <Datagrid rowClick="show">
        <TextField source="name" sortable={false} />
        <TextField source="alpha_2" sortable={false} />
        <TextField source="alpha_3" sortable={false} />
        <TextField
          source="residency_risk"
          label={"Residency risk level"}
          sortable={false}
        />
        <TextField
          source="citizenship_risk"
          label={"Citizenship risk level"}
          sortable={false}
        />
        <TextField source="risk_level_business" sortable={false} />
        <TextField source="risk_level_bank" sortable={false} />
        <TextField source="currencies" sortable={false} />
        <TextField source="note" sortable={false} />
        <TextField source="alert_message" sortable={false} />
        <TextField source="show_mrz" sortable={false} />
      </Datagrid>
    </List>
  );
}
