import React from "react";
import { Datagrid, List, TextField, ListProps } from "react-admin";

export function TickerList(props: Omit<ListProps, "children">) {
  return (
    <List bulkActionButtons={false} {...props} pagination={false}>
      <Datagrid rowClick="show">
        <TextField source="name" sortable={false} />
        <TextField source="base" sortable={false} />
        <TextField source="quote" sortable={false} />
        <TextField source="status" sortable={false} />
        <TextField source="min_value" sortable={false} />
        <TextField source="price_precision" sortable={false} />
        <TextField source="quantity_precision" sortable={false} />
        <TextField source="value_precision" sortable={false} />
        <TextField source="fee_precision" sortable={false} />
      </Datagrid>
    </List>
  );
}
