import { client } from '../client';

export interface UserListCountry {
	id: string;
	name: string;
}
export interface GeneralData {
	deposit_statuses: string[];
	deposit_methods: string[];
	assets: string[];
	withdrawal_statuses: string[];
	withdrawal_methods: string[];
}
export function getUserStatuses(): Promise<string[]> {
	return client.get('/users/statuses').then(res => res.data);
}

export function getCountriesForUserListPage(): Promise<UserListCountry[]> {
	return client
		.get('/generalData/countries?for_page=user_list')
		.then(res => res.data);
}

export function getDataForPaymentFilters(): Promise<GeneralData> {
	return client.get('/generalData/forPaymentFilters').then(res => res.data);
}
