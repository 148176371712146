import React from "react";
import {
  Datagrid,
  Filter,
  FilterProps,
  List,
  ListProps,
  SelectInput,
  TextField,
  TextInput,
} from "react-admin";
import { usePaymentChoices } from "../hooks/usePaymentChoices";
import { AmountFeeField } from "./AmountFeeField";
import { DefaultPagintion } from "./DefaultPagination";
import { FormattedDateInput } from "./FormattedDateInput";
import { ListDateField } from "./ListDateField";

function DepositFilter(props: Omit<FilterProps, "children">) {
  const { depositMethodChoices, depositStatusChoices, assetChoices } =
    usePaymentChoices();

  return (
    <Filter {...props}>
      <TextInput source="email" alwaysOn />
      <SelectInput
        label="Asset name"
        source="asset"
        choices={assetChoices}
        alwaysOn
        translateChoice={false}
      />
      <SelectInput
        source="method"
        choices={depositMethodChoices}
        alwaysOn
        translateChoice={false}
      />
      <SelectInput
        source="status"
        label="Payment status"
        choices={depositStatusChoices}
        alwaysOn
        translateChoice={false}
      />
      <TextInput
        label="Address/ Account number"
        source="bank_account"
        alwaysOn
      />
      <TextInput source="external_id" label="External ID" alwaysOn />
      <FormattedDateInput
        label="Payment date from"
        source="created_from"
        alwaysOn
      />
      <FormattedDateInput
        label="Payment date to"
        source="created_to"
        alwaysOn
      />
    </Filter>
  );
}

const LinkToUserField = ({ record = { user_unique_ref: "", email: "" } }) => {
  const link = `/users#/users/${record.user_unique_ref}`;
  return <a href={link}>{record.email}</a>;
};
LinkToUserField.defaultProps = { label: "Email" };

export function DepositList(props: Omit<ListProps, "children">) {
  return (
    <List
      bulkActionButtons={false}
      filters={<DepositFilter />}
      pagination={<DefaultPagintion />}
      perPage={25}
      {...props}
    >
      <Datagrid>
        <TextField label="Payment ID" source="uuid" />
        <LinkToUserField />
        <TextField source="method" />
        <TextField label="PSP" source="external_service" />
        <TextField source="status" label="Payment status" />
        <TextField source="asset" label="Asset name" />
        <AmountFeeField label="Payment amount/ costs" />
        <TextField source="name" label="Full name" />
        <TextField label="Address/ Account number" source="bank_account" />
        <TextField label="External ID" source="external_id" />
        <ListDateField label="Payment date/ time" />
      </Datagrid>
    </List>
  );
}
