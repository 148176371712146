import React from "react";
import {
  Datagrid,
  Edit,
  EditProps,
  EmailField,
  FileField,
  FormTab,
  NumberField,
  Pagination,
  ReferenceManyField,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin";
import { AmountFeeField } from "../AmountFeeField";
import { ListDateField } from "../ListDateField";

export const UserEdit = (props: Omit<EditProps, "children">) => {
  return (
    <Edit {...props}>
      <TabbedForm>
        <FormTab label="profile">
          <TextField source="name" label="Full name" />
          <TextField source="uniqueReference" label="Reference" />
          <EmailField source="email" />
          <TextField source="status" label="User status" />
          <TextField source="suspended" label="Is user suspended?" />
          <TextField source="risk_level_manual" label="Risk level (manual)" />
          <TextField source="risk_level" />
          <NumberField source="risk_score" />
          <NumberField label="Tier" source="tier.level" />
          <TextField label="Registration date/ time" source="registered" />
          <TextField source="tradeFee.taker" label="Trade (Taker) fee" />
          <TextField source="tradeFee.maker" label="Trade (Maker) fee" />
          <TextInput
            multiline={true}
            label="Merchant Notes"
            source="merchant_notes"
          />
        </FormTab>
        <FormTab label="KYC">
          <TextField label="KYC date/ time" source="kyc_date" />
          <TextField label="Country of citizenship" source="tier.country" />
          <TextField
            label="Country of citizenship (second)"
            source="tier.country_second"
          />
          <TextField label="Country of residency" source="tier.residency" />
          <TextField source="name" label="Full name" />
          <TextField label="Date of birth" source="tier.dob" />
          <TextField label="Personal code" source="tier.personal_code" />
          <TextField label="ID number" source="tier.id_number" />
          <TextField label="ID type" source="tier.id_type" />
          <TextField label="ID expiry" source="tier.id_expire" />
          <TextField source="manualDocument" label="ID status" />
          <TextField source="manualFace" label="Selfie status" />
          <FileField
            label="ID front side"
            source="files.id_side_a"
            title="ID front side"
            target="_blank"
          />
          <FileField
            label="ID back side"
            source="files.id_side_b"
            title="ID back side"
            target="_blank"
          />
          <FileField
            label="User selfie"
            source="files.selfie"
            title="User selfie"
            target="_blank"
          />
          <FileField
            label="Proof of address"
            source="files.address_pdf"
            title="Proof of address"
            target="_blank"
          />
        </FormTab>
        <FormTab label="Open Orders">
          <ReferenceManyField reference="orders" target="user_id" label="">
            <Datagrid>
              <TextField source="id" />
              <TextField source="side" />
              <TextField label="Market" source="ticker" />
              <TextField source="price" />
              <TextField source="quantity" />
              <TextField source="executed" />
              <TextField label="Taker Fee" source="taker_rate" />
              <TextField label="Maker Fee" source="maker_rate" />
              <TextField label="Opened" source="createdAt" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Balances">
          <ReferenceManyField reference="accounts" target="userId" label="">
            <Datagrid>
              <TextField source="asset" label="Asset name" />
              <TextField label="Total" source="balance" />
              <TextField source="reserved" />
              <TextField label="Available" source="available_balance" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Deposits">
          <ReferenceManyField reference="deposits" target="user_id" label="">
            <Datagrid>
              <TextField label="Payment ID" source="uuid" />
              <TextField source="method" />
              <TextField label="PSP" source="external_service" />
              <TextField source="status" label="Payment status" />
              <TextField source="asset" label="Asset name" />
              <AmountFeeField label="Payment amount/ costs" />
              <TextField
                label="Address/ Account number"
                source="bank_account"
              />
              <TextField label="External ID" source="external_id" />
              <ListDateField label="Payment date/ time" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Withdrawals">
          <ReferenceManyField reference="withdrawals" target="userId" label="">
            <Datagrid>
              <TextField label="Payment ID" source="uuid" />
              <TextField source="method" />
              <TextField label="PSP" source="psp" />
              <TextField source="status" label="Payment status" />
              <TextField source="asset" label="Asset name" />
              <AmountFeeField label="Payment amount/ costs" />
              <TextField label="Address/ Account number" source="address" />
              <TextField label="External ID" source="external_id" />
              <ListDateField label="Payment date/ time" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Transactions">
          <ReferenceManyField
            reference="transactions"
            target="user_id"
            label=""
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="id" label="Transaction ID" />
              <TextField source="type" label="Transaction type" />
              <TextField source="asset" label="Asset name" />
              <TextField source="amount" />
              <TextField source="balance" />
              <TextField source="note" />
              <ListDateField label="Transaction date/ time" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="DEPOSIT ADDRESSES">
          <ReferenceManyField
            reference="cryptoAddress"
            target="user_id"
            label=""
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="asset" label="Asset name" />
              <TextField source="address" />
              <ListDateField label="Address creation date" />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
        <FormTab label="Credits">
          <ReferenceManyField reference="credits" target="userId" label="">
            <Datagrid>
              <TextField label="Id" source="uuid" />
              <TextField source="status" />
              <TextField source="asset" label="Asset name" />
              <TextField source="amount" />
              <TextField source="repaid" />
              <ListDateField />
            </Datagrid>
          </ReferenceManyField>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
