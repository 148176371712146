import { client } from '../client';

export function getUserStatuses(): Promise<string[]> {
	return client.get('/users/statuses').then(res => res.data);
}

export interface UserListCountry {
	id: string;
	name: string;
}

export function getCountriesForUserListPage(): Promise<UserListCountry[]> {
	return client
		.get('/generalData/countries?for_page=user_list')
		.then(res => res.data);
}
